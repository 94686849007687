import React from "react"
import Layout from "../components/layout.js"
import { graphql } from "gatsby"
import BodyWrapper from "../components/Layouts/BodyWrapper/index.js"
import NextPage from "../components/ResuableComponents/NextPage/index.js"
import Footer from "../components/MediatorComponents/Footer/index.js"
import ProductsLayout from "../components/MediatorComponents/ProductsLayout/index.js"
import Seo from "../components/seo.js"
import ItemContext from "../components/Context/ItemsFilter.js"

const Shop = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title='Shop' />
      <BodyWrapper className='p-t-15 c-15'>
        <div className='flex flex-r flex-sb p-b-300 p-t-40-br p-b-90-br'>
          <h2>Shop</h2>
          <h2>[{data?.products?.totalCount}]</h2>
        </div>
        <ItemContext>
          <ProductsLayout products={data?.products.nodes} />
        </ItemContext>
        <NextPage nextPageName={"studio"} nextPageSlug={"/studio"} location={"/studio"} display={false} />
        <Footer location={location} />
      </BodyWrapper>
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query ShopQuery {
    products:allDatoCmsProduct(filter: { shopifyProductId: { ne: "" } }) {
      nodes {
        id
        cat {
          slug
        }
        launchDate
        heroimage {
          gatsbyImageData(aspectRatio: 0.9)
          url
        }
        name
        year
        totalQuantity
        currentQuantity
        slug
      }
      totalCount
    }
  }
`
